import { h } from 'preact';
export default (function (_ref) {
  var i18n = _ref.i18n,
      logout = _ref.logout,
      username = _ref.username;
  return [h("span", {
    className: "uppy-ProviderBrowser-user",
    key: "username"
  }, username), h("button", {
    type: "button",
    onClick: logout,
    className: "uppy-u-reset uppy-c-btn uppy-ProviderBrowser-userLogout",
    key: "logout"
  }, i18n('logOut'))];
});